import * as React from 'react';
import type { HeadFC } from 'gatsby';

const PacketPage: React.FC = () => {
  return <div>Sponsib packet!!!</div>;
};

export default PacketPage;

export const Head: HeadFC = () => <title>Sponsorship Packet</title>;
